<template>
  <div class="warp adv-box">
    <swiper
      ref="swiper"
      :options="advList.length > 1 ? swiperOption : swiperOption2"
      v-if="advList.length > 0"
    >
      <swiper-slide
        class="swiperSlide"
        v-for="(item, index) in advList"
        :key="index"
      >
        <img
          class="advimg"
          @click="goAdv(item)"
          :data-item="JSON.stringify(item)"
          :key="index"
        />
      </swiper-slide>
      <div
        class="swiper-pagination"
        slot="pagination"
        v-if="advList.length != 1 && hasPagination"
      ></div>
    </swiper>
  </div>
</template>
<script>
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { imgDecyptApi } from "@/api/app";
import { jumpAdvs } from "@/utils/getConfig";

export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: {
    advList: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    hasPagination: {
      type: Boolean,
      required: false,
      default() {
        return true;
      },
    },
  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiper;
    },
  },
  data() {
    return {
      place: require("@/assets/png/place.png"),
      // 轮播图配置
      swiperOption: {
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "my-bullet-active",
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 3000,
        },
        on: {
          click: function (e) {
            let item = JSON.parse(e.target.dataset.item);
            jumpAdvs(item);
          },
        },
      },
      swiperOption2: {
        loop: false,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          bulletActiveClass: "my-bullet-active",
        },
        autoplay: {
          disableOnInteraction: false,
          delay: 5000,
        },
      },
    };
  },
  mounted() {
    let dom = document.querySelectorAll(".adv-box .swiperSlide");
    let domlen = dom.length;
    for (let i = 0; i < domlen; i++) {
      let imgDom = dom[i].querySelector("img");
      imgDecyptApi(
        this.advList.length > 1
          ? this.advList[dom[i].getAttribute("data-swiper-slide-index")].image
          : this.advList[0].image
      ).then((url) => {
        if (imgDom) {
          imgDom.src = url;
        }
      });
    }
  },
  methods: {
    /**
     * * 跳转广告
     *  * @param {*} item 广告信息
     */
    goAdv(item) {
      jumpAdvs(item);
    },
  },
};
</script>
<style lang="scss" scoped>
.advimg {
  width: 100%;
  height: 100%;
  text-align: center;
}

.warp {
  /deep/ .swiper-pagination {
    bottom: 6px;
    left: auto;
    width: 100%;
    right: 20px;
    text-align: right;
    margin-right: 20px;
    z-index: 999;
  }

  /deep/ .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 2px 4px;
    background: white;
    opacity: 0.5;
  }

  /deep/ .my-bullet-active {
    background: white;
    opacity: 1;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }

  .swiperSlide {
    background-color: rgb(247, 248, 250);
    text-align: center;

    img {
      height: 224px;
    }
  }
}
</style>
