import axios from "@/utils/request";

/*********************** 首页 ******************/
// 获取tab列表
export function getTabs() {
  return axios.get(`/aF122d35/b676bb53`);
}
// 广告点击统计
export function advClick(data) {
  let config = {
    headers: {
      "log-id": data.id,
      "log-type": data.type || "adv",
    },
  };
  return axios.get(`/aF122d35/763e1e8c`, config);
}
// 获取站长推荐列表
export function getWebApps() {
  return axios.get(`/aF122d35/d8192dBa/WebmasterRecommended`);
}
// 获取实用工具列表
export function getToolApps() {
  return axios.get(`/aF122d35/d8192dBa/PragmaticTool`);
}
// 获取app列表
export function getApps(id) {
  return axios.get(`/aF122d35/d8192dBa?id=`+id);
}
/**
 * 截取URL参数
 * @param name 需要截取url的参数
 * @returns
 */
function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
  var r = window.location.search.substr(1).match(reg); //匹配目标参数
  if (r != null) return unescape(r[2]);
  return null; //返回参数值
}
// 记录
export function visitRecord() {
  let config = {
    headers: {
      reqLog: "reqLog",
    },
  };
  if (getUrlParam("dc")) {
    config.headers.channel = getUrlParam("dc");
  }
  return axios.get(`/aF122d35/2d3b7338`, config);
}
