<template>
  <div class="tabItem">
    <div class="main" v-if="!loading">
      <div class="appBoxs">
        <div
          class="appBox"
          @click="jump(item)"
          v-for="item in hengApp"
          :key="item.id"
        >
          <ImgDecypt :key="item.id" :src="item.icon" class="icon"></ImgDecypt>
          <div class="name">{{ item.name }}</div>
          <!-- <div class="install">{{ item.type == "app" ? "安装" : "打开" }}</div> -->
        </div>
      </div>
      <!-- 轮播图广告 -->
      <AdvSwiper
        :advList="advList"
        v-if="advList.length"
        :hasPagination="false"
        class="advSwiper"
      />
      <div
        class="appItem"
        @click="jump(item)"
        v-for="item in shuApp"
        :key="item.id"
      >
        <div class="leftBox">
          <ImgDecypt :key="item.id" :src="item.icon" class="cover"></ImgDecypt>
          <div class="appInfo">
            <div class="appName">
              {{ item.name }}
            </div>
            <div class="desc">
              {{ item.desc }}
            </div>
          </div>
        </div>
        <div class="downloadBox" v-if="item.type == 'app'">
          <div class="download"></div>
          <span>安装</span>
        </div>
        <div class="open" v-else>打开</div>
      </div>
      <div class="sizeBox"></div>
      <!-- 底部轮播图广告 -->
      <AdvsSwiper
        :advList="bottomAdvList"
        v-if="bottomAdvList.length"
        :hasPagination="false"
        class="bottomAdvSwiper"
      />
      <NoData v-if="!hengApp.length && !shuApp.length" />
    </div>
    <Loading v-else />
  </div>
</template>
<script>
import { getApps } from "@/api/home";
import { jumpAdvs } from "@/utils/getConfig";
import Loading from "@/components/Loading/index.vue";
import NoData from "@/components/NoData/index.vue";
import AdvSwiper from "@/components/AdvSwiper";
import AdvsSwiper from "@/components/AdvsSwiper";

export default {
  name: "tabItem",
  components: {
    ImgDecypt: () => import("@/components/ImgDecypt"),
    Loading,
    NoData,
    AdvSwiper,
    AdvsSwiper,
  },
  props: {
    moduleId: String,
  },
  data() {
    return {
      hengApp: [],
      shuApp: [],
      topAdv: [],
      advList: [],
      bottomAdvList: [],
      loading: true,
    };
  },
  created() {
    this.getApps();
  },
  methods: {
    async getApps() {
      let res = await this.$Api(getApps, this.moduleId);
      this.loading = false;
      if (res && res.code == 200) {
        this.hengApp = res.data.hengApp || [];
        this.shuApp = res.data.shuApp || [];
        this.advList = res.data.ads.filter((item) => item.location == "1");
        this.topAdv = res.data.ads.filter((item) => item.location == "3");
        this.bottomAdvList = res.data.ads.filter(
          (item) => item.location == "2"
        );
      }
    },
    jump(data) {
      jumpAdvs(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.tabItem {
  padding: 2px 8px 0;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 150px);
  .main {
    .appBoxs {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-row-gap: 10px;
      grid-column-gap: 5px;
      margin-bottom: 20px;
      .appBox {
        width: 67px;
        .icon {
          height: 67px;
          width: 67px;
          border-radius: 4px;
          overflow: hidden;
        }
        .name {
          font-size: 12px;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 5px 0;
        }
        .install {
          height: 25px;
          width: 47px;
          border-radius: 25px;
          color: #f25d61;
          background: #dedddd;
          text-align: center;
          line-height: 25px;
          font-size: 13px;
          font-weight: 800;
          margin: 0 auto;
        }
      }
    }
    .advSwiper {
      border-radius: 10px;
      overflow: hidden;
      margin-bottom: 10px;
      /deep/ .swiper-container {
        height: 122px;
      }
      /deep/ img {
        height: 100%;
      }
    }
    .appItem {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 70px;
      border-radius: 6px;
      color: #f25d61;
      border-bottom: 1px solid #f5f5f5;
      cursor: pointer;
      box-sizing: border-box;
      padding: 0 4px;
      .leftBox {
        display: flex;
        align-items: center;
        .cover {
          height: 53px;
          width: 53px;
          border-radius: 8px;
          overflow: hidden;
        }
        .appInfo {
          width: 210px;
          margin-left: 10px;
          .appName {
            font-size: 16.3px;
            color: #f25d61;
          }
          .desc {
            font-size: 12.6px;
            color: #f25d61;
            margin-top: 3px;
          }
        }
      }
      .downloadBox {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: linear-gradient(
          32deg,
          rgb(255, 0, 111),
          rgb(43, 36, 242)
        );
        width: 70.6px;
        height: 32.6px;
        border-radius: 32.6px;
        font-size: 13px;
        color: #fff;
        .download {
          height: 13px;
          width: 19px;
          background: url("./../../../assets/png/download.png") no-repeat;
          background-size: 100% 100%;
          margin-right: 1.5px;
        }
      }
      .open {
        background-image: linear-gradient(
          32deg,
          rgb(255, 0, 111),
          rgb(43, 36, 242)
        );
        width: 70.6px;
        height: 32.6px;
        border-radius: 32.6px;
        line-height: 32.6px;
        font-size: 13px;
        text-align: center;
        color: #fff;
      }
    }
    .sizeBox {
      height: 200px;
    }
    .bottomAdvSwiper {
      overflow: hidden;
      width: 100%;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 5;
      /deep/ .swiper-container {
        height: 64px;
      }
      /deep/ img {
        height: 100%;
      }
    }
  }
}
@media screen and (min-width: 530px) {
  .bottomAdvSwiper {
    position: absolute !important;
  }
}
</style>
