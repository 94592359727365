<template>
  <div class="home">
    <div class="headerBox" @click="jump(topAdv[0])" v-if="topAdv.length">
      <ImgDecypt
        :key="topAdv[0].image"
        :src="topAdv[0].image"
        class="topAdv"
      ></ImgDecypt>
      <!-- <div class="leftBox">
        <div class="logo"></div>
        <div class="appInfo">
          <div class="title">樱桃APP下载导航</div>
          <div class="desc" @click="jumpUrl('https://e3h1r.cc')">
            永久网址：https://e3h1r.cc
          </div>
        </div>
      </div>
      <div class="rightBox" @click="showSave = !showSave">收藏网址</div> -->
    </div>
    <div class="headerTip">
      <div class="desc">
        <span class="navName">樱桃APP导航</span> 永久网址：<span
          @click="jumpUrl('https://eve4.cc')"
          >eve4.cc</span
        >
      </div>
      站长亲测所有APP无捆绑无毒免费下载，站长商务TG：<span
        @click="jumpUrl('https://t.me/chuyang698')"
        >@chuyang698</span
      >
    </div>
    <van-tabs class="myTabs" swipeable :lazy-render="true" v-if="tabList.length">
      <van-tab :title="item.name" v-for="item in tabList" :key="item.cid">
        <TabItem :moduleId="item.cid" />
      </van-tab>
    </van-tabs>
    <van-overlay :show="showSave" z-index="9">
      <div class="qrcodeBox" @click.stop>
        <div class="qrcodeTitle">保存二维码</div>
        <div class="qrcode">
          <div ref="qrCodeUrl"></div>
        </div>
        <div class="btnBox" @click="downloadQrcode">下载二维码图片</div>
        <div class="btnBox" @click="saveLink">复制链接保存</div>
        <div class="btnBox" @click="showSave = false">关闭</div>
        <div class="kindTip">
          同时浏览器右上角菜单，可设置将幸福里添加至手机桌面打开更便捷永不迷路！
        </div>
      </div>
    </van-overlay>
  </div>
</template>
<script>
import { getTabs, getApps } from "@/api/home";
import { visitRecord } from "@/api/home";
import TabItem from "./components/tabItem.vue";
import QRCode from "qrcodejs2";
import domToImage from "dom-to-image";
import { Toast } from "vant";
import { jumpAdvs } from "@/utils/getConfig";

export default {
  name: "home",
  components: {
    ImgDecypt: () => import("@/components/ImgDecypt"),
    TabItem,
  },
  data() {
    return {
      tabList: [],
      url: "",
      showSave: false,
      topAdv: [],
    };
  },
  mounted() {
    this.getTabs();
    this.generateCode();
    setTimeout(() => {
      visitRecord();
    }, 5000);
  },
  methods: {
    async getTabs() {
      let res = await this.$Api(getTabs);
      if (res && res.code == 200) {
        this.tabList = res.data || [];
        this.getApps();
      }
    },
    async getApps() {
      let res = await this.$Api(getApps, this.tabList[0].cid);
      if (res && res.code == 200) {
        this.topAdv = res.data.ads.filter((item) => item.location == "3");
      }
    },
    jumpUrl(url) {
      window.open(url);
    },
    // 生成二维码
    generateCode() {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: window.location.href,
          width: 170,
          height: 170,
          colorDark: "rgb(74, 141, 200)",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },
    // 下载二维码
    downloadQrcode() {
      domToImage.toPng(this.$refs.qrCodeUrl).then((dataUrl) => {
        var link = document.createElement("a");
        link.download = "qrcode.png";
        link.href = dataUrl;
        link.click();
      });
    },
    // 保存链接
    saveLink() {
      let req = window.location.href;
      this.$copyText(req).then(
        () => {
          Toast("复制成功");
        },
        () => {
          Toast("复制失败");
        }
      );
    },
    jump(data) {
      console.log(123123);
      jumpAdvs(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  /deep/ .van-tabs {
    .van-tabs__wrap {
      height: 28px;
      .van-tab {
        font-size: 14px;
        color: #646566;
        background: white;
      }
      .van-tab--active {
        color: #ff0168;
      }
      .van-tabs__line {
        height: 2px;
        width: 16px;
        background-image: -webkit-gradient(
          linear,
          0 0,
          100% 0,
          from(#ff0163),
          to(#2b24f2)
        );
      }
      .van-tabs__nav--line {
        padding-bottom: 12px;
      }
    }
  }
  .headerBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(32deg, #2b24f2, #ff006f);
    height: 55px;
    .topAdv {
      width: 100%;
      height: 100%;
    }
    // padding: 0 14px;
    .leftBox {
      display: flex;
      align-items: center;
      .logo {
        width: 40px;
        height: 40px;
        background: url("./../../assets/png/logo.png") no-repeat;
        background-size: 100% 100%;
      }
      .appInfo {
        margin-left: 5px;
        color: white;
        .title {
          font-size: 14px;
          font-weight: 800;
        }
        .desc {
          font-size: 12px;
        }
      }
    }
    .rightBox {
      width: 67px;
      height: 30px;
      color: #f25d61;
      background-color: white;
      text-align: center;
      line-height: 30px;
      font-size: 12px;
      border-radius: 30px;
      cursor: pointer;
    }
  }
  .headerTip {
    font-size: 11px;
    padding: 5px 10px;
    color: #f25d61;
    background: #f5f5f5;
    .navName {
      font-size: 11px;
      font-weight: 600;
      color: #f25d61;
      text-decoration: none;
    }
    span {
      color: #555;
      text-decoration: underline;
    }
  }
  /deep/ .van-overlay {
    height: calc(100vh - 55px);
    margin-top: 55px;
    position: absolute;
  }
  .qrcodeBox {
    width: 100%;
    height: 100%;
    background: linear-gradient(62deg, #2b24f2, #ff006f);
    background-size: 180%;
    background-position: 0 100%;
    color: white;
    .qrcodeTitle {
      font-size: 12px;
      text-align: center;
      padding: 9px;
    }
    .qrcode {
      height: 200px;
      width: 200px;
      color: black;
      background-color: white;
      margin: 0 auto 10px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .btnBox {
      border: 1px solid white;
      width: 160px;
      height: 35px;
      text-align: center;
      line-height: 35px;
      margin: 0 auto 15px;
      border-radius: 35px;
      cursor: pointer;
    }
    .kindTip {
      font-size: 12.6px;
      padding: 0 12px;
      line-height: 18px;
    }
  }
}
@media screen and (min-width: 530px) {
  .qrcode {
    height: 150px !important;
    width: 150px !important;
  }
}
</style>
